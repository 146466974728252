.bioText {
    width: auto;
}

.sideBar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 15vh;
    height: calc(var(--vh, 1vh) * 15);
    overflow: hidden;
    padding: 1px;
    background: linear-gradient(90deg, #f5f5f5 0%, #e9e9e9 100%);
    color: #5f5e5e;
    font-size: 14px;
    text-transform: uppercase;
}

.row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.ctaButton {
    cursor: pointer;
    a {
        color: inherit;
        text-decoration: none;
    }
    a:hover {
        text-decoration: underline;
    }
}

#des-only {
    display: none;
}

@media only screen and (max-width: 768px) {
    #mob-only {
        display: none;
    }
    #des-only {
        display: block;
    }
}
