.loadContainer {
    width: 100%;
    height: 100vh;
    background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0.71) 0%,
        rgba(255, 255, 255, 0.5) 100%
    );
    position: absolute;
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4px;
    /* -webkit-mask: url("../../../public/images/logo.svg") center 40%/20%
            no-repeat,
        linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude; */
    z-index: 101;
    padding: 1px;
}

.textContainer {
    color: #5f5e5e;
    font-size: 14px;
    text-transform: uppercase;
    text-align: center;
}

.row {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.buttonContainer {
    z-index: 102;
}

.okButton {
    padding: 4px 12px;
    min-width: 100px;
    margin-top: 16px;
    cursor: pointer;
    width: 100%;
    color: #5f5e5e;
    font-size: 14px;
    text-transform: uppercase;
    border-radius: 8px;
    /* border: 1px solid #c8c5c5; */
    border: none;
    appearance: none;
    background: #efefef;
}

.okButton:hover {
    filter: invert(10%);
    -webkit-filter: invert(10%);

    transition: filter 0.3s ease;
}
