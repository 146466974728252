.loadContainer {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f5f5f5;
}

.logoContainer {
    -webkit-animation: logoFade 2s infinite;
    animation: logoFade 2s infinite;
}

.logoContainer > img {
    max-width: 16px;
}

@-webkit-keyframes logoFade {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes logoFade {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
