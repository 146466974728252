.cardContainer {
    /* flex: 0 1; */
    /* min-width: 150px; */
    /* min-height: 49.5%; */

    -webkit-filter: blur(100px);
    filter: blur(100px);
    transition: all 0.2s ease-in-out;
    transform: translate3d(0, 0, 0);
    will-change: transform;
}

.cardContainer > img {
    width: 100%;
    object-fit: cover;
}

.cardContainer:hover {
    -webkit-filter: blur(0px);
    filter: blur(0px);
}
