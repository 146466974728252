html {
    background: #f5f5f5;
    margin: 0;
    padding: 0;
}

* {
    box-sizing: border-box;
}

a {
    appearance: none;
    color: inherit;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

body {
    margin: 0;
    font-family: NTR, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 14px;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

.pageContainer {
    width: 100%;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    overflow: HIDDEN;
}
.row {
    display: flex;
    flex-direction: row;
}

.galleryContainer {
    /* display: flex;
    flex-direction: column;
    gap: 1px;
    flex-wrap: wrap; */
    height: 85vh;
    height: calc(var(--vh, 1vh) * 85);
    /* height: -webkit-fill-available; */

    overflow-x: scroll;
    position: relative;
    /* align-content: flex-start; */
}

.slideTrack {
    display: flex;
    flex-direction: column;
    gap: 1px;
    flex-wrap: wrap;
    height: inherit;
    align-content: flex-start;
    /* animation: scroll 40s linear infinite; */
}

.formContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: fit-content;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    /* backdrop-filter: blur(100px); */
}

.formInput {
    width: 232px;
    padding: 8px;
    align-items: center;
    gap: 10px;
    background: rgba(255, 255, 255, 0);
    border: none;
    border-bottom: 1px solid #5f5e5e0b;
    color: #5f5e5e;
    font: inherit;
    backdrop-filter: blur(10px);
}
.formInput:focus {
    outline: none;
}

.formInput::placeholder {
    color: #5f5e5e4b;
}

.backButton {
    position: absolute;
    bottom: 20px;
    color: #5f5e5e4b;
}

textarea.formInput {
    resize: none;
}

.contactBg {
    background: radial-gradient(
        circle,
        rgba(255, 0, 0, 1) 0%,
        rgb(237, 130, 0) 50%,

        rgba(255, 0, 0, 0) 100%
    );
}

.tooltipContainer {
    border-radius: 8px;
    background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0.7) 0%,
        rgba(255, 255, 255, 0.21) 100%
    );
    pointer-events: none;

    backdrop-filter: blur(5px);
    display: flex;
    width: 333px;
    padding: 8px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    flex-shrink: 0;
    position: fixed;
    opacity: 0;
    top: 0;
    margin: 4px;
    transition: 1s all ease-in-out;
    font-size: 14px;
    color: #5f5e5e;
    text-transform: uppercase;
}

@media only screen and (max-width: 768px) {
    .bioText {
        width: 100%;
    }

    /* .sideBar {
        gap: 24px;
    } */

    .ctaButton {
        justify-content: center;
        align-items: center;
    }

    .tooltipContainer {
    }
    .formContainer {
        padding: 16px;
    }
    .formInput {
        width: 100%;
    }
}
